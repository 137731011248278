<ng-container *ngIf="loaded; else loading">
  <div [hidden]="!data || !fieldsLoaded || this.cicoService.activeStep.currentStep > 1">
    <form #form="ngForm" class="redesign">
      <div class="required-text instructions" translate>misc.required_fields</div>

      <app-basic-input *ngIf="fields['booking'] && data.blank" ngDefaultControl [name]="'booking'" [(ngModel)]="data.incident.reservation.reservation_id" [description]="fields['booking'].name" [tooltip]="fields['booking'].description" [required]="fields['booking'].required" [errorMsg]="'validation.reservation_id'" [disabled]="!data.blank" [submited]="submited"></app-basic-input>

      <div class="flex-2col">
        <app-date-input *ngIf="fields['arrival']" ngDefaultControl [name]="'arrival'" [(date)]="data.incident.reservation.arrival" [description]="fields['arrival'].name" [tooltip]="fields['arrival'].description" [required]="fields['arrival'].required" [errorMsg]="'validation.arrival'" [disabled]="!data.blank" (dateChange)="setMinDeparture()" [submitted]="submited"></app-date-input>
        <app-date-input *ngIf="fields['departure']" ngDefaultControl [name]="'departure'" [(date)]="data.incident.reservation.departure" [description]="fields['departure'].name" [tooltip]="fields['departure'].description" [min]="minDepartureDate" [required]="fields['departure'].required" [errorMsg]="'validation.departure'" [validation]="'future'" [disabled]="!data.blank" [submitted]="submited"></app-date-input>
        <app-basic-input *ngIf="fields['unit']" ngDefaultControl [name]="'unit'" [(ngModel)]="data.incident.reservation.unit_name" [description]="fields['unit'].name" [tooltip]="fields['unit'].description" [required]="fields['unit'].required" [disabled]="true"></app-basic-input>
        <app-basic-input *ngIf="fields['total_price']" ngDefaultControl [name]="'total_price'" [(ngModel)]="data.incident.reservation.total_price" [description]="fields['total_price'].name" [tooltip]="fields['total_price'].description" [required]="fields['total_price'].required" [disabled]="true"></app-basic-input>
      </div>

      <app-button-group *ngIf="fields['travel_purpose']" ngDefaultControl name="travel_purpose" [description]="fields['travel_purpose'].name" [tooltip]="fields['travel_purpose'].description" [options]="[['booking_data.travel_purpose.private_trip', TravelPurpose.private_trip], ['booking_data.travel_purpose.business_trip', TravelPurpose.business_trip]]" [(ngModel)]="data.incident.reservation.travel_purpose" [required]="fields['travel_purpose'].required && !travel_purpose_lock" [disabled]="travel_purpose_lock" [submitted]="submited" (click)="checkCityTax(); checkFields()"></app-button-group>

      <div class="flex-2col" *ngIf="fields['time_of_arrival'] || fields['time_of_departure']">
        <app-time-input *ngIf="fields['time_of_arrival']" ngDefaultControl name="time_of_arrival" inputId="timeOfArrival" [(ngModel)]="modelFor(fields['time_of_arrival'].id).value" [description]="fields['time_of_arrival'].name" [tooltip]="fields['time_of_arrival'].description" [required]="fields['time_of_arrival'].required"></app-time-input>
        <app-time-input *ngIf="fields['time_of_departure']" ngDefaultControl name="time_of_departure" inputId="timeOfDeparture" [(ngModel)]="modelFor(fields['time_of_departure'].id).value" [description]="fields['time_of_departure'].name" [tooltip]="fields['time_of_departure'].description" [required]="fields['time_of_departure'].required"></app-time-input>
      </div>

      <div *ngIf="fields['coming_from'] || fields['destination']">
        <app-basic-input *ngIf="fields['coming_from']" ngDefaultControl name="coming_from" inputId="comingFrom" [(ngModel)]="modelFor(fields['coming_from'].id).value" [description]="fields['coming_from'].name" [tooltip]="fields['coming_from'].description" [required]="fields['coming_from'].required"></app-basic-input>
        <app-basic-input *ngIf="fields['destination']" ngDefaultControl name="destination" inputId="destination" [(ngModel)]="modelFor(fields['destination'].id).value" [description]="fields['destination'].name" [tooltip]="fields['destination'].description" [required]="fields['destination'].required"></app-basic-input>
      </div>

      <div *ngIf="fields['border_crossing'] || fields['border_crossing_date']">
        <app-basic-input *ngIf="fields['border_crossing']" ngDefaultControl name="border_crossing" inputId="borderCrossing" [(ngModel)]="modelFor(fields['border_crossing'].id).value" [description]="fields['border_crossing'].name" [tooltip]="fields['border_crossing'].description" [required]="fields['border_crossing'].required"></app-basic-input>
        <app-date-input *ngIf="fields['border_crossing_date']" ngDefaultControl name="border_crossing_date" [(date)]="modelFor(fields['border_crossing_date'].id).value" [description]="fields['border_crossing_date'].name" [tooltip]="fields['border_crossing_date'].description" [required]="fields['border_crossing_date'].required" [errorMsg]="'validation.date'" [validation]="'past'" [today]="true" [submitted]="submited"></app-date-input>
      </div>

      <ng-container *ngIf="data.blank && fields['fellows']">
        <app-button-group ngDefaultControl name="more_fellows" [(ngModel)]="hasFellows" [options]="[['misc.no_string', false], ['misc.yes_string', true]]" [description]="fields['fellows'].name | translate" [tooltip]="fields['fellows'].description" [required]="true" [submitted]="submited" (ngModelChange)="fellowsCheck($event)"></app-button-group>
        <div class="flex-2col" *ngIf="hasFellows">
          <app-select *ngFor="let type of ['adults', 'children']" ngDefaultControl [name]="'number_of_' + type" [items]="numbersFellows" [(ngModel)]="data.incident.reservation[type]" [description]="'service.check_in.fellows.' + type | translate" [placeholder]="'service.check_in.fellows.' + type | translate" [tooltip]="'service.check_in.descriptions.' + type | translate" (ngModelChange)="fellowsQty(type)" [triggerKeyboard]="true" [required]="false" [disabled]="!data.blank" [submited]="submited"></app-select>
        </div>
      </ng-container>

      <app-form-fields *ngIf="data.module.fields" [form]="data.incident" [module]="data.module" [redesign]="true" [submited]="submited"></app-form-fields>
      <app-pms-invoice-address-form [data]="data" *ngIf="ownInvoiceAddress && data.incident.reservation.primary_guest.address.valid()" [staticAddress]="false"></app-pms-invoice-address-form>
    </form>
  </div>

  <div *ngIf="rule && this.cicoService.activeStep.currentStep > 1">
    <div *ngFor="let product of rule.products; let i = index">
      <app-main-title class="animation-out" [mainTitle]="product.product.name"></app-main-title>
      <div class="container animation-out">
        <app-product [product]="product.product" [index]="i"></app-product>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <app-loader [small]="true" [included]="true" [headline]="'misc.loading'"></app-loader>
</ng-template>
