import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { PmsBaseDirective } from 'pms_base/base.directive';
import { ConfirmName, OverlayAction, OverlayType, PmsModType, Step } from 'pms_enums';
import { GenericData } from 'pms_models/generic_data';
import { PmsService } from 'pms_service';
import { filter, take } from 'rxjs/operators';
import { Field } from 'models/field';
import { StorageService } from 'services/storage.service';
import { StepperService } from 'services/pms/stepper.service';
import { InfoComponent } from '../info/info.component';
import { PmsSignatureComponent } from '../signature/signature.component';
import { PmsGuest } from 'models/pms/pms_guest';

@Component({
  selector: 'app-pms-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})

export class PmsCheckInConfirmComponent extends PmsBaseDirective implements OnInit, OnDestroy {
  processes = [];
  success = [];
  canceled = [];
  failed = [];

  confirmName: string;
  sending: boolean;
  alert: any;
  done: boolean;
  legalInfo: any;
  legal: any;
  legalTypes = ['city_tax', 'visitor_tax', 'tourism_fee'];
  incident: any;
  ui_messages: any;


  confirmation: any;
  doneButton: string;
  doorStep: string;
  processText: string = '';
  showSignature: boolean;
  digitalSignatureField: any;
  resReceivedNotSuccess: boolean = false;

  constructor(public globals: Globals,
              private pmsService: PmsService,
              private api: ApiService,
              private _cdr: ChangeDetectorRef,
              protected stepperService: StepperService,
              public cicoService: PmsCiCoService,
              private storageService: StorageService) {
    super(cicoService, Step.confirm, undefined, stepperService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.cicoService.setAutoSkipUntilStep(undefined);
  }

  protected fetchData() {
    this.subscriptions.add(this.cicoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
      this.data = data;
      this.ui_messages = this.cicoService.ui_messages();

      this.legalInfo = this.legalInfos();
      this.legal = this.globals.business?.legal;

      if (this.data.module.type === PmsModType.co) {
        this.checkCoAnswers();
      }

      this.data.incident.prevention = this.data.blank ? '' : this.prevention() || '';
      this.data.incident.prevented = this.data.blank || this.data.incident.prevention.length > 0;
      this.cicoService.setShowFooter(true);

      try {
        this.data.incident.checks.debug_paid = this.data.incident.reservation.foliosPaid(this.data);
      } catch (e) {
        try {
          Bugsnag.notify(e.message);
        } catch (e) {
          console.log(e.message);
        }
      }

      let name;
      if (this.data.incident.prevented) {
        name = ConfirmName.send;
      } else {
        name = this.data.module.type === PmsModType.ci ? ConfirmName.ci : ConfirmName.co;
      }
      this.confirmName = this.globals.translate(`misc.${name}`);

      this.signatureCheck();
    }));
  }

  createIncident() {
    this.clearPassportVisa();
    this.resetStates();
    this.sending = true;
    this.globals.clearAlert(true);
    this.cicoService.disableButtons(true);

    const time = this.cicoService.duration();

    this.data.incident.reservation.persons = this.data.incident.reservation.adults + this.data.incident.reservation.children;
    this.legalInfo = [];
    this.api.post('incident', {
      duration: time.seconds < 3600 ? time.nano : null, //less then 1 hour
      uuid: this.data.uuid,
      payment: this.data.payment,
      module: this.data.module.type,
      settings: this.data.module.settings,
      late_reg_form: this.cicoService.late_reg_form,
      should_payment: this.cicoService.should_payment,
      params: Object.assign(this.data.incident)
    }).subscribe((success: any) => {
      this.pmsService.clear();
      this.startProcess(success);
    }, () => {
      this.sending = false;
      this.cicoService.openOverlay(OverlayType.reset);
      this.cicoService.disableButtons(false);
    });
  }

  startProcess(response) {
    window.scrollTo(0, 0);
    this.cicoService.toggleInactivity(false);

    if (!response.processes.length && response.confirmation) {
      this.data.done = true;
      this.confirmation = response;
      return;
    }

    this.processes = response.processes;
    this.incident = response.incident;

    this.checkProcess(0);

    this.subscriptions.add(this.cicoService.suppressGuard.subscribe(suppressGuard => {
      this.done = suppressGuard;
      this.cicoService.disableBack = true;
    }));
  }

  checkProcess(index) {
    const task = this.processes[index];
    const params = {uuid: this.data.incident.reservation.uuid, task: task};
    this.processText = task;
    this.api.silentPut('incident/' + this.incident, params).subscribe((response: any) => {
      if (response.success) {
        this.success.push(task);
      } else {
        this.failed.push(task);
        this.canceled = this.processes.filter(elem => !this.success.includes(elem) && !this.failed.includes(elem));
      }
      if (response.confirmation) {
        this.data.done = true;
        this.confirmation = response.confirmation;
        this.doneButton = this.globals.translate(this.confirmation.room ? 'service.check_in.general.finish' : 'misc.finish');
        this.storageService.removeKey(this.data.incident.getStorageKey(), this.data.business.code);
        this.cicoService.toggleInactivity(true);
        this.cicoService.infoScreen = true;
        if (response.success && this.failed.length === 0) {
          if (this.confirmation.encoderData?.autoEncode) {
            this.cicoService.setShowFooter(false);
          }
          this.doorStep = this.confirmation.door_step;
          this.cicoService.encoderData = this.confirmation.encoderData;
          this.cicoService.suppressGuardSubj.next(this.doorStep !== 'cards');
        } else {
          this.resReceivedNotSuccess = true;
          this.cicoService.suppressGuardSubj.next(true);
        }
      } else {
        this.checkProcess(index + 1);
      }
    }, () => { });
  }

  resetStates() {
    this.processes = [];
    this.success = [];
    this.canceled = [];
    this.failed = [];
  }

  openOverlay(type) {
    this.api.silentGet('legal/' + type).subscribe((success: any) => {
      this.cicoService.openComponentOverlay(InfoComponent, {legal: success.legal, kind: type});
    });
  }

  legalInfos() {
    const legal = (this.data?.business || this.globals.business)?.legal || {};
    return this.legalTypes.map(type => {
      if (legal[type]) {
        return {type: type, text: this.globals.translate('legal.form.' + type)};
      }
    }).filter(entry => entry !== undefined);
  }

  checkCoAnswers() {
    const answers = this.data.incident.field_values.filter(field => field.check_out_if !== 'co_none' && field.kind === 'polar');
    this.filterQuestions().forEach((question, _i) => {
      const field = answers.find(answer => answer.id === question.id);
      const status = !!(field.value);
      field.valid = (question.check_out_if === 'co_false' && !status) || (question.check_out_if === 'co_true' && status);
    });
    this.data.incident.checks.questions = !this.filterQuestions().length || answers.every(answer => answer.valid);
  }

  filterQuestions(): Field[] {
    return this.data.module.fields.filter(field => field.check_out_if !== 'co_none' && field.kind === 'polar');
  }

  prevention(): string {
    switch (this.data.module.type) {
      case PmsModType.ci:
        if (this.data.module.settings.wrong_device && this.data.module.settings?.should_check_in && this.inTime()) {
          return 'wrong_device';
        }
        if (this.cicoService.step.invoice) {
          if (this.data.folioInfo?.error) {
            if (!this.data.module.settings?.allow_folio_error) {
              return 'not_loaded';
            }
          } else if (!this.data.incident.checks.paid_folios && this.data.module.settings?.required_payments && this.cicoService.should_payment) {
            return 'not_paid';
          }
        }
        if (this.data?.module?.settings?.should_check_in && !this.inTime()) {
          return 'invalid_time';
        }
        if (!this.data.incident.reservation[`can_${this.data.module.pmsType()}`]) {
          return 'cant_perform';
        }
        if (this.data.incident.checks.hasOwnProperty('auth_done') && !this.data.incident.checks.auth_done) {
          return 'not_authorized';
        }
        break;
      case PmsModType.co:
        if (this.data.module.settings.wrong_device && this.inTime()) {
          return 'wrong_device';
        }
        if (this.cicoService.step.invoice && this.data.folioInfo?.error) {
          return 'not_loaded';
        }
        if (!this.data.incident.checks.paid_folios) {
          return 'not_paid';
        }
        if (!this.inTime()) {
          return 'invalid_time';
        }
        if (!this.data.incident.reservation['can_' + this.data.module.pmsType()]) {
          return 'cant_perform';
        }
        if (!this.data.incident.checks.questions) {
          return 'invalid_answers';
        }
        break;
    }
  }

  inTime() {
    return this.data.incident.reservation.in_time;
  }

  signatureCheck() {
    this.digitalSignatureField = this.data.module.fields.find(field => field.identifier === 'signature');
    this.showSignature = this.digitalSignatureField?.active && this.data.incident.reservation.should_sign && !(this.data.incident.reservation.signedBySca() || this.data.incident.reservation.authenticated);
    if (this.showSignature) {
      this.subscriptions.add(this.cicoService.send.pipe(filter(Boolean), take(1)).subscribe(() => {
        this.createIncident();
      }));
    }
  }

  open(ok) {
    if (this.data.incident.reservation.signature && !ok) {
      this.createIncident();
    } else {
      if (this.showSignature) {
        this.cicoService.openComponentOverlay(PmsSignatureComponent, {
          data: this.data,
          confirmName: this.confirmName
        }, 'signature', true);
      } else {
        this.createIncident();
      }
    }
    this._cdr.detectChanges();
  }

  endProcess() {
    this.cicoService.closeOverlay(OverlayAction.cancel, false);
  }

  private clearPassportVisa() {
    this.data.incident.reservation.all_guests().forEach((guest: PmsGuest) => {
      if (!guest.passport_data) {
        guest.passport_id = null;
        guest.passport_authority = null;
        guest.passport_date = null;
        guest.passport_expire = null;
      }
      if (!guest.visa_data) {
        guest.visa_number = null;
        guest.visa_date = null;
        guest.visa_expire = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.cicoService.infoScreen = false;
    this.resReceivedNotSuccess = false;
    super.ngOnDestroy();
    this.cicoService.confirmNameSubj.next(ConfirmName.next);
  }
}
